<template>
  <div>
    <div class="tw-border-b tw-border-gray-300">
      <div class="tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-5">
        <h3 class="tw-text-2xl tw-capitalize tw-font-medium">
          {{ $tc('_customerInvoices') }}
        </h3>
      </div>
    </div>
    <div class="tw-py-4 tw-px-3">
      <company-invoices />
    </div>
  </div>
</template>

<script>
import CompanyInvoices from '../components/company/CompanyInvoices';

export default {
  props: [],

  data() {
    return {
      active: '',
    };
  },
  components: {
    CompanyInvoices,
  },
};
</script>
